import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {IndexComponent} from './index/index.component'
import {EditComponent} from './edit/edit.component'
import {ReactiveFormsModule, FormsModule} from '@angular/forms'
import {CasesRoutingModule} from './cases-routing.module'
import {CaseDetailsComponent} from './edit/case-details/case-details.component'
import {CaseMemberFormGroupComponent} from './edit/case-member-form-group/case-member-form-group.component'
import {PhoneModule} from '@/lib/app/modules/phone.module'
import {TooltipModule} from '@/lib/app/components/tooltip/tooltip.module'
import {NgxMaskModule} from 'ngx-mask'
import {PaginatorModule} from '@/lib/app/components/paginator/paginator.module'
import {CaseClientInfoComponent} from './edit/case-client-info/case-client-info.component'
import {InvalidFeedbackModule} from '@/lib/app/components/invalid-feedback/invalid-feedback.module'
import {CaseCreatorAccessSwitchComponent} from '@/org/app/components/cases/edit/case-creator-access-switch/case-creator-access-switch.component'
import {LocationNameComponent} from '@/lib/app/components/location-name/location-name.component'
import {RequiredIndicatorComponent} from '@/lib/app/components/required-indicator/required-indicator.component'
import {PreNeedLeadInfoComponent} from '@/org/app/components/cases/edit/pre-need-lead-info/pre-need-lead-info.component'
import {CaseBusinessInfoComponent} from '@/org/app/components/cases/edit/case-business-info/case-business-info.component'
import {IsTestIndicatorComponent} from '@/lib/app/components/is-test-indicator/is-test-indicator.component'
import {CaseActionsComponent} from '@/org/app/components/cases/edit/case-actions/case-actions.component'
import {TempFileUploaderModule} from '@/lib/app/components/temp-file-uploader/temp-file-uploader.module'

@NgModule({
  declarations: [
    IndexComponent,
    EditComponent,
    CaseDetailsComponent,
    CaseMemberFormGroupComponent,
    CaseClientInfoComponent,
    CaseCreatorAccessSwitchComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CasesRoutingModule,
    FormsModule,
    PhoneModule,
    TooltipModule,
    NgxMaskModule.forRoot(),
    PaginatorModule,
    InvalidFeedbackModule,
    LocationNameComponent,
    RequiredIndicatorComponent,
    PreNeedLeadInfoComponent,
    CaseBusinessInfoComponent,
    IsTestIndicatorComponent,
    CaseActionsComponent,
    TempFileUploaderModule,
  ],
  exports: [CaseMemberFormGroupComponent],
})
export class CasesModule {}
