<div>
  <div class="position-fixed w-100 top-0">
    <lib-pending-request-loader></lib-pending-request-loader>
  </div>

  <nav *ngIf="isActiveOrgSet" class="primary-nav border-bottom">
    <div class="container-fluid">
      <div class="d-flex align-items-center justify-content-between" style="padding-top: 2px">
        <div *ngIf="screenWidth > 800" class="d-flex align-items-center justify-content-start">
          <a routerLink="/" class="d-none d-lg-inline-block pe-3">
            <img src="assets/logo-white.svg" class="mx-4 my-1" style="width: 117px; height: 64px" />
          </a>
          <ul class="nav nav-strip fs-6 mt-1">
            <li *ngFor="let item of navItems" class="nav-item">
              <a
                class="nav-link px-3 py-2 my-2"
                [ngClass]="{'btn btn-primary btn-sm bg-white text-primary': item.inviteButton}"
                [routerLink]="item.routerLink"
                routerLinkActive="active"
                [routerLinkActiveOptions]="item.routerLinkOptions || {exact: false}"
                id="{{ item.tourStepID }}">
                <i [ngClass]="{'fa-regular fa-plus pe-1': item.inviteButton}"></i>
                {{ item.label }}
              </a>
            </li>
          </ul>
        </div>

        <div *ngIf="screenWidth <= 800" class="d-flex align-items-center justify-content-between">
          <div class="dropdown">
            <button
              class="btn btn-light text-primary dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              Menu
            </button>
            <ul class="dropdown-menu">
              <li *ngFor="let item of navItems">
                <a
                  class="dropdown-item"
                  [routerLink]="item.routerLink"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="item.routerLinkOptions || {exact: false}">
                  {{ item.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div *ngIf="isUserAndOrgLoaded" class="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-end">
          <div *ngIf="!hasMultipleOrgs" class="d-none d-md-block py-2 my-2 me-3 text-white">
            <span class="me-2"> {{ this.activeOrg.activeOrg.name }} </span>
          </div>

          <div *ngIf="hasMultipleOrgs" class="max-w-300 mx-2">
            <select
              class="form-select rounded-pill"
              [ngModel]="this.activeOrg.activeOrg.id"
              (ngModelChange)="onActiveOrgChange($event)">
              <option *ngFor="let org of this.activeOrg.enterpriseOrgsList" [ngValue]="org.id">
                {{ org.name }}
              </option>
            </select>
          </div>

          <a
            class="btn btn-link dropdown-toggle nav-link py-2 my-2 text-white"
            [ngClass]="{
              'border-2 border-primary': isSettingsActive()
            }"
            type="button"
            id="accountDropdownBtn"
            data-bs-toggle="dropdown"
            aria-expanded="false">
            <i class="fas fa-user h5 mb-0 me-2"></i>
            <span class="me-2 is-pii">{{ user.name }}</span>
          </a>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="accountDropdownBtn">
            <li>
              <a class="dropdown-item" routerLink="/settings"> <i class="fas fa-cog fa-fw me-2"></i>Settings </a>
            </li>
            <li>
              <button class="dropdown-item" (click)="onLogout()">
                <i class="fas fa-sign-out-alt fa-fw me-2"></i>Log out
              </button>
            </li>
            <!--            <li>-->
            <!--              <button class="dropdown-item" (click)="productTour.startProductTour()">-->
            <!--                <i class="fas fa-solid fa-flag-swallowtail me-2"></i>Take a tour-->
            <!--              </button>-->
            <!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </nav>

  <main class="flex-grow-1 pb-5">
    <div class="alert-container">
      <lib-alert></lib-alert>
    </div>

    <div class="container-fluid p-3 p-lg-5">
      <router-outlet *ngIf="isUserAndOrgLoaded"></router-outlet>
    </div>
  </main>

  <footer
    class="position-fixed bottom-0 w-100 d-none d-md-flex bg-white border-end-0 border"
    style="display: flex; padding: 0.625rem 2rem; align-items: center; gap: 2rem">
    <img src="/assets/logo-labelled.svg" style="max-height: 1rem" />

    <org-feedback-form />
  </footer>

  <lib-confirmation-modal></lib-confirmation-modal>
  <lib-hubspot-chat *ngIf="isUserAndOrgLoaded && isProduction" [authUser]="user"></lib-hubspot-chat>
</div>

<business-app-onboarding-modal *ngIf="isUserAndOrgLoaded" />
<!-- (triggerTour)="productTour.startProductTour()" /> -->
<!--<business-app-product-tour />-->
