import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {Alert, AlertType} from '../models/alert'

@Injectable({providedIn: 'root'})
export class AlertService {
  private _alerts: Alert[] = []

  get alerts(): Alert[] {
    return this._alerts
  }

  success(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({...options, type: AlertType.Success, message}))
  }

  error(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({...options, type: AlertType.Error, message}))
  }

  info(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({...options, type: AlertType.Info, message}))
  }

  warn(message: string, options?: Partial<Alert>) {
    this.alert(new Alert({...options, type: AlertType.Warning, message}))
  }

  alert(alert: Alert) {
    this.pushAlert(alert)
  }

  clear() {
    this._alerts = []
  }

  removeAlert(alert: Alert) {
    if (!this.alerts.includes(alert)) return
    this._alerts = this._alerts.filter(x => x !== alert)
  }

  private pushAlert(alert: Alert) {
    this._alerts = [...this._alerts, alert]
    if (alert.auto_close) {
      setTimeout(() => this.removeAlert(alert), alert.auto_close)
    }
  }
}
