import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {AuthLayoutComponent} from '@/org/app/layouts/auth-layout/auth-layout.component'
import {authGuard} from '@/lib/app/guards/auth.guard'
import {PublicLayoutComponent} from '@/org/app/layouts/public-layout/public-layout.component'
import {StyleGuideComponent} from '@/lib/app/components/style-guide/style-guide.component'
import {errorRoutes} from '@/lib/app/components/errors/errors-routing.module'
import {MaintenanceModeComponent} from '@/lib/app/components/maintenance-mode/maintenance-mode.component'
import {createHostGuardFor} from '@/lib/app/guards/host.guard'
import {environment} from '@/lib/environments/environment'

const hostGuard = createHostGuardFor(environment.businessHostNames)

const routes: Routes = [
  {
    path: '',
    component: AuthLayoutComponent,
    canActivate: [hostGuard, authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('@/org/app/components/dashboard/dashboard.component').then(c => c.DashboardComponent),
      },
      {
        path: 'invite',
        pathMatch: 'full',
        loadComponent: () => import('@/org/app/components/create-case/create.component').then(c => c.CreateComponent),
      },
      {
        path: 'cases/new',
        redirectTo: 'invite',
        pathMatch: 'full',
      },
      {
        path: 'cases',
        loadChildren: () => import('@/org/app/components/cases/cases.module').then(m => m.CasesModule),
      },
      {
        path: 'users',
        loadChildren: () => import('@/org/app/components/org-users/org-users.module').then(m => m.OrgUsersModule),
      },
      {
        path: 'user-invitations',
        loadChildren: () =>
          import('@/org/app/components/org-user-invitations/org-user-invitations.module').then(
            m => m.OrgUserInvitationsModule
          ),
      },
      {
        path: 'resources',
        loadChildren: () => import('@/org/app/components/resources/resources.module').then(m => m.ResourcesModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('@/org/app/components/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('@/org/app/components/settings/settings.module').then(m => m.SettingsModule),
      },
    ],
  },
  {
    path: '',
    component: PublicLayoutComponent,
    canActivate: [hostGuard],
    children: [
      {
        path: 'auth',
        loadChildren: () => import('@/org/app/components/auth/auth.module').then(m => m.AuthModule),
      },
      {
        path: 'invitation',
        loadChildren: () => import('@/lib/app/components/invitation/invitation.module').then(m => m.InvitationModule),
      },
      {
        path: 'agreements',
        loadChildren: () => import('@/org/app/components/contracts/contracts.module').then(m => m.ContractsModule),
      },
      {
        path: 'maintenance',
        component: MaintenanceModeComponent,
      },
      {
        path: 'styles',
        component: StyleGuideComponent,
      },
    ],
  },
  ...errorRoutes,
  {
    path: '**',
    redirectTo: '',
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always', // allows access to parent params from child routes
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
