<!--Only show this section if the org allows org employees to access the case, -->
<!--AND if the person who created the case is an org employee-->
<ng-container *ngIf="!isEnterpriseAdmin && doesOrgAllowOrgUserAccessToCases && isCaseCreatorOrgUser">
  <div *ngIf="canEnableCollaboAccessWithoutRequest">
    <div class="d-flex align-items-start mb-2">
      <h4>Edit your contact's case</h4>
    </div>
    <div class="form-check form-switch ps-0 d-flex align-items-start mb-4">
      <label for="access_switch" class="form-check-label me-4" [class.text-muted]="!canAuthUserToggleCaseAccess">
        <!-- (click)="false"> allows mobile users to tap on the icon to pop the tooltip without triggering the switch -->
        <ng-container *ngIf="!isAfterCareCase"
          >Turn ON to edit your contact's case information on their behalf within Legacy Planner.</ng-container
        >
        <ng-container *ngIf="isAfterCareCase"
          >Turn ON to edit your contact's case information on their behalf within Executor Assistant.</ng-container
        >
      </label>
      <input
        *ngIf="showInput"
        type="checkbox"
        class="ms-1 form-check-input form-check-input-status-text flex-shrink-0"
        data-form-check-status-on="On"
        data-form-check-status-off="Off"
        role="switch"
        [ngModel]="isCaseCreatorMemberOfCase"
        (change)="handleAccessChange()"
        [disabled]="!canAuthUserToggleCaseAccess"
        id="access_switch" />
    </div>
  </div>

  <div *ngIf="!canEnableCollaboAccessWithoutRequest">
    <div class="d-flex mb-3 justify-content-between align-items-center">
      <div class="me-3">
        <ng-container *ngIf="isAfterCareCase"
          >Send a request to your contact to edit your contacts case information in Executor Assistant.</ng-container
        >
        <ng-container *ngIf="!isAfterCareCase"
          >Send a request to your contact to edit your contacts case information in Legacy Planner.</ng-container
        >
      </div>
      <div class="flex-shrink-0">
        <ng-container *ngIf="canSendRequest">
          <button class="btn btn-outline-primary" (click)="requestCreatorCaseAccess()">Send request</button>
        </ng-container>

        <div
          *ngIf="isCaseCreatorMemberOfCase || caseCreatorCollabRequest"
          [ngClass]="{
                'text-info':    caseCreatorCollabRequest?.status === CaseCollaborationRequestStatuses.PENDING,
                'text-success': caseCreatorCollabRequest?.status === CaseCollaborationRequestStatuses.ACCEPTED || !caseCreatorCollabRequest,
                'text-danger':  caseCreatorCollabRequest?.status === CaseCollaborationRequestStatuses.REJECTED,
             }">
          {{ discoverLabelForRequestStatus(caseCreatorCollabRequest?.status) }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="canResendRequest" class="mt-3">
    <button class="btn btn-outline-primary" (click)="requestCreatorCaseAccess()">Resend request</button>
  </div>

  <div *ngIf="canRevokeCaseAccess">
    <button *ngIf="!canEnableCollaboAccessWithoutRequest" class="btn btn-primary me-3" (click)="handleAccessChange()">
      Remove permission
    </button>

    <a [href]="enduserCaseUrl" target="_blank" class="btn btn-outline-primary">
      <i class="far fa-external-link me-2"></i>
      <ng-container *ngIf="isAfterCareCase">Open Executor Assistant</ng-container>
      <ng-container *ngIf="!isAfterCareCase">Open Legacy Planner</ng-container>
    </a>
  </div>
</ng-container>
