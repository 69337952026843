import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {InvitationStatuses, ICaseInvitation} from '@/lib/app/models/case-invitation.interface'
import {DatePipe} from '@angular/common'
import {ICase} from '@/lib/app/models/case.interface'
import {AlertService} from '@/lib/app/services/alert.service'
import {UntypedFormBuilder, Validators} from '@angular/forms'
import {KeysIn} from '@/lib/app/domain/KeysIn'
import {CaseInvitationService} from '@/org/app/services/case-invitation.service'
import {ConfirmService} from '@/lib/app/services/confirm.service'
import {FormErrorService} from '@/lib/app/services/form-error.service'
import {CaseService} from '@/org/app/services/case.service'
import {formatPhone} from '@/lib/app/models/phone.model'
import {filter} from 'lodash'

@Component({
  selector: 'org-case-client-info',
  templateUrl: './case-client-info.component.html',
})
export class CaseClientInfoComponent implements OnInit, OnChanges {
  @Input() case: ICase
  @Output() updated = new EventEmitter<any>()
  mode: 'view' | 'edit' = 'view'
  form = this._fb.group({
    first_name: [''],
    last_name: [''],
    phone: [''],
    // leaving this as non-required to allow employees to submit the form when case created w/o email
    // though, we'll prevent anyone from removing an email to void an existing invite
    email: ['', [Validators.email]],
  } as KeysIn<Partial<ICaseInvitation>>)

  constructor(
    private _alerts: AlertService,
    private _fb: UntypedFormBuilder,
    private _caseInvitations: CaseInvitationService,
    private _confirm: ConfirmService,
    private _formErrors: FormErrorService,
    private _cases: CaseService,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.case.owner.case_invitation)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.form.patchValue(this.case.owner.case_invitation)
  }

  get caseOwnerStatus(): {label: string; tooltip: string; click?: any} {
    if (this.isInvitePending(this.case.owner.case_invitation)) {
      return {
        label: 'Referred',
        tooltip: "The contact has been invited to use Cadence.<br><br>(Click to copy the client's invitation link)",
        click: () => {
          this.copyToClipboard(this.case.owner.case_invitation.link)
        },
      }
    } else if (this.isInviteAccepted(this.case.owner.case_invitation)) {
      // could show more statuses based on the org billing type here
      const datepipe: DatePipe = new DatePipe('en-US')
      const serviced_at = datepipe.transform(this.case.serviced_at, 'medium')
      return {
        label: 'Active',
        tooltip: `The contact has signed up with a Cadence account.<br><br>Active since: ${serviced_at}`,
      }
    }
  }

  get shouldDisableEdit() {
    // if user has paid or has accepted the invite don't allow to edit.
    // In scenario where there was a self sign up user will not have an invitation but will have paid.
    const isSelfSignup = !this.case.owner.case_invitation
    const isInvitationAccepted = this.case.owner.case_invitation?.status === 'accepted'

    if (isSelfSignup) {
      return true
    } else {
      return isInvitationAccepted
    }
  }

  toggleDemoStatus = () => {
    this._cases.update(this.case.id, {is_test: this.case.is_test})
  }

  isInvitePending(invitation: ICaseInvitation) {
    return invitation && invitation.status === InvitationStatuses.PENDING
  }

  isInviteDraft(invitation: ICaseInvitation) {
    return invitation && invitation.status === InvitationStatuses.DRAFT
  }

  isInviteAccepted(invitation: ICaseInvitation) {
    return invitation && invitation.status === InvitationStatuses.ACCEPTED
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    this._alerts.success('Copied to clipboard')
  }

  onEdit() {
    this.mode = 'edit'
  }

  async onSubmit() {
    if (!this.form.valid) {
      return
    }
    const inviteStatus = this.case.owner.case_invitation.status
    const email = this.form.get('email').value
    const hasEmailChanged = email != this.case.owner.case_invitation.email
    if (hasEmailChanged && email && (inviteStatus === 'pending' || inviteStatus === 'bounced')) {
      const msg = `
        <p>This will send a new email to <strong>${email}</strong> with an invitation to this Case.</p>
        <p>Continue?</p>`
      if (!(await this._confirm.confirm(msg))) {
        return
      }
    }
    try {
      this.form.patchValue(
        await this._caseInvitations.update(this.case.id, this.case.owner.case_invitation.id, this.form.value),
      )
    } catch (e) {
      this._formErrors.handle(this.form, e)
      return
    }
    this._alerts.success('Success')
    this.mode = 'view'
    this.updated.emit()
  }

  onCancelEdit() {
    this.mode = 'view'
    this.form.patchValue(this.case.owner.case_invitation)
  }

  async onBookMeeting() {
    const confirmed = await this._confirm.confirm(
      'This will open a page where you can book a meeting with a Cadence advisor on behalf of the contact.<br><br>Proceed?',
    )
    if (!confirmed) {
      return
    }
    window.open(this.case.meeting_link, '_blank')
  }

  protected readonly InvitationStatuses = InvitationStatuses

  async onSendInvitation() {
    const notifications = [
      this.case.owner.email ? `<strong>${this.case.owner.email}</strong>` : '',
      this.case.owner.case_invitation.phone && this.case.owner.case_invitation.send_sms
        ? `<strong>${formatPhone(this.case.owner.case_invitation.phone)}</strong>`
        : '',
    ]
    const confirmed = await this._confirm.confirm(
      `This will send the invitation to ${filter(notifications).join(
        ' and ',
      )} with instructions on how to get started with Cadence.`,
    )
    if (!confirmed) {
      return
    }

    try {
      await this._caseInvitations.send(this.case.id, this.case.owner.case_invitation.id)
    } catch (e) {
      this._alerts.error(e)
      return
    }

    this._alerts.success('Invitation sent')
    this.updated.emit()
  }

  async resendInitialCaseInvitation() {
    const notifications = [
      this.case.owner.email ? `<strong>${this.case.owner.email}</strong>` : '',
      this.case.owner.case_invitation.phone && this.case.owner.case_invitation.send_sms
        ? `<strong>${formatPhone(this.case.owner.case_invitation.phone)}</strong>`
        : '',
    ]
    const confirmed = await this._confirm.confirm(
      `This will resend the invitation to ${filter(notifications).join(
        ' and ',
      )} with instructions on how to get started with Cadence.`,
    )
    if (!confirmed) {
      return
    }

    try {
      await this._caseInvitations.resendInitialInvitation(this.case.id, this.case.owner.case_invitation.id)
    } catch (e) {
      this._alerts.error(e)
      return
    }

    this._alerts.success('Invitation sent')
    this.updated.emit()
  }
}
