import {Component, OnInit} from '@angular/core'
import {CaseStatus, ICase} from 'projects/library/src/app/models/case.interface'
import {AuthService} from 'projects/library/src/app/services/auth.service'
import {AlertService} from '@/lib/app/services/alert.service'
import {ILocation} from '@/org/app/models/location.interface'
import {find, isNil, isNull, keys, pick} from 'lodash'
import {ActivatedRoute, Router} from '@angular/router'
import {UserService} from '@/org/app/services/user.service'
import {IUser} from '@/lib/app/models/user.interface'
import {Filters} from '@/lib/app/models/filters'
import {IPaginationMeta} from '@/lib/app/models/paginated.interface'
import {CaseInvitationService} from '@/org/app/services/case-invitation.service'
import {ActiveOrgStore} from '@/org/app/stores/active-org.store'
import {LocationStore} from '@/lib/app/stores/location.store'
import {CaseStore} from '@/org/app/stores/case.store'
import {Sort} from '@/lib/app/models/sort'
import {isPreNeedContactAtExpired} from '@/lib/app/domain/preneed'

@Component({
  selector: 'org-index',
  templateUrl: './index.component.html',
})
export class IndexComponent implements OnInit {
  public users: IUser[]
  public isFetchingCases: boolean = false
  public filters: Filters
  public sort: Sort
  public pagination: IPaginationMeta = {
    current_page: 1,
  }
  public isPreNeedContactAtExpired = isPreNeedContactAtExpired

  public get cases(): ICase[] {
    return this._caseStore.items
  }

  constructor(
    private _caseStore: CaseStore,
    public locationStore: LocationStore,
    private _alerts: AlertService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _users: UserService,
    public activeOrg: ActiveOrgStore,
    public caseInvitationService: CaseInvitationService,
    public auth: AuthService,
  ) {
    this.filters = new Filters({
      name: [''],
      owner_search: [''],
      handled_by_id: [null, Number],
      location_id: [null, Number],
      invite_type: [null, String],
      is_test: [null, Number],
    })
    this.sort = new Sort()
  }

  async ngOnInit(): Promise<void> {
    this.filters.patchForm(this._route.snapshot.queryParams)
    this.sort.setFromQuery(this._route.snapshot.queryParams)

    await Promise.all([this.fetchCases(), this.fetchLocations(), this.fetchUsers()])
  }

  async fetchCases() {
    this.isFetchingCases = true
    const queryParams = {
      ...this.filters.query(),
      ...this.sort.query(),
    }
    if (this.pagination.current_page !== 1) {
      queryParams['page'] = this.pagination.current_page
    }

    this._router.navigate([], {
      relativeTo: this._route,
      queryParams,
    })

    queryParams['org_id'] = this.activeOrg.activeOrgId

    try {
      const res = await this._caseStore.fetchPaginated(queryParams)
      this.pagination = res.meta
    } catch (e) {
      this._alerts.error(e)
    }
    this.isFetchingCases = false
  }

  get locations(): ILocation[] {
    return this.locationStore.items
  }

  async fetchLocations() {
    await this.locationStore.fetchAll(this.activeOrg.activeOrgId)
  }

  async fetchUsers() {
    try {
      this.users = await this._users.fetchUsers(this.activeOrg.activeOrgId)
    } catch (e) {
      this._alerts.error(e)
    }
  }

  isLoaded() {
    return !isNil(this.cases) && !isNil(this.locations) && !isNil(this.users)
  }

  caseStatus(c: ICase): string {
    if (c.status === CaseStatus.STATUS_PRE_PLANNER) {
      return 'LP'
    } else if (c.status === CaseStatus.STATUS_PRE_PLANNER_LOCKED) {
      return 'LP'
    } else if (c.status === CaseStatus.STATUS_AFTER_CARE) {
      return 'EA'
    }
    return ''
  }

  onSelectPage(page: number) {
    this.pagination.current_page = page
    this.fetchCases()
  }

  onNextPage() {
    this.pagination.current_page++
    this.fetchCases()
  }

  onPrevPage() {
    this.pagination.current_page--
    this.fetchCases()
  }

  onClearFilterAndSort() {
    this.filters.resetForm()
    this.sort.reset()
    this.onSelectPage(1)
  }

  onFiltersSubmit() {
    this.onSelectPage(1)
  }

  get hasFilters(): boolean {
    if (this.filters.hasFilters()) {
      return true
    }
    // only the query params for the filters
    const filterQueryParams = pick(this._route.snapshot.queryParams, keys(this.filters.form.value))

    return !!find(filterQueryParams, f => !!f)
  }

  get hasSort(): boolean {
    if (this.sort.hasSort()) {
      return true
    }
    // only the query params for the filters
    const sortQueryParams = pick(this._route.snapshot.queryParams, ['sort', 'order'])

    return !!find(sortQueryParams, f => !!f)
  }

  onSort($event: null | string) {
    if ($event) {
      // event comes from select options values which are 'verified_at:asc', 'verified_at:desc' for example
      this.setFromSerialized($event)
    } else {
      this.sort.reset()
    }
    this.fetchCases()
  }

  setFromSerialized(serializedSort: string) {
    const parts = serializedSort.split(':')
    this.sort.set(parts[0], parts[1] as 'asc' | 'desc')
  }

  protected readonly CaseStatus = CaseStatus

  get shouldHideDemoCases() {
    if (isNull(this.filters.form.value.is_test)) {
      return false
    }
    return !this.filters.form.value.is_test
  }
  set shouldHideDemoCases(val) {
    this.filters.patchForm({is_test: val ? 0 : null})
    this.onFiltersSubmit()
  }
}
