import {Injectable} from '@angular/core'
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpContextToken} from '@angular/common/http'
import {Observable, throwError} from 'rxjs'
import {AuthService} from '@/lib/app/services/auth.service'
import {catchError} from 'rxjs/operators'
import {Router} from '@angular/router'
import {CadenceError} from '@/lib/app/models/cadence-error'
import {AlertService} from '@/lib/app/services/alert.service'
import {invoke} from 'lodash'

export const ERROR_HANDLERS = new HttpContextToken<{[code: number]: any}>(() => ({}))

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService, public router: Router, private _alerts: AlertService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const defaultHandlers = {
          401(interceptor: ErrorInterceptor) {
            if (interceptor.auth.token) {
              interceptor.auth.logout()
              interceptor.router.navigate(['/auth/login'])
            }
          },
          403(interceptor: ErrorInterceptor) {
            console.log('@lib/app/interceptors/error.interceptor', '403 response', err)
            interceptor.router.navigate(['/e/403'])
          },
        }
        const handlers = Object.assign({}, defaultHandlers, request.context.get(ERROR_HANDLERS))
        invoke(handlers, err.status, this)

        console.error('@lib/app/interceptors/error.interceptor', 'Received error response', err)
        return throwError(() => new CadenceError(err))
      })
    )
  }
}
