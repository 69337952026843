<div class="d-flex justify-content-between align-items-start mb-2">
  <h4>Contact information</h4>

  <div *ngIf="mode === 'view'" class="dropdown">
    <button class="btn btn-light py-0 px-2 bg-white border-0" data-bs-toggle="dropdown">
      <i class="fa-solid fa-ellipsis-v"></i>
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <li>
        <span [tooltip]="shouldDisableEdit ? 'This information cannot be edited' : ''">
          <button class="dropdown-item" (click)="onEdit()" [disabled]="shouldDisableEdit">
            <i class="fa-solid fa-edit me-2"></i>Edit information
          </button>
        </span>
      </li>
      <li>
        <button class="dropdown-item" (click)="onBookMeeting()">
          <i class="fa-solid fa-calendar-day me-2"></i>
          Book a meeting with a Cadence advisor
          <i class="fa-solid fa-external-link-alt text-muted small ms-1"></i>
        </button>
      </li>
      <li *ngIf="case.owner.case_invitation.status === InvitationStatuses.PENDING">
        <button class="dropdown-item" (click)="resendInitialCaseInvitation()">
          <i class="fa-light fa-envelope me-2"></i>
          Resend invitation&hellip;
        </button>
      </li>
    </ul>
  </div>
</div>

<ng-container *ngIf="mode === 'view'">
  <div class="row mb-3">
    <div class="col-sm-3 text-muted">Name</div>
    <div class="col-sm-9">
      <span class="me-2 text-truncate is-pii" [title]="case.owner.name">{{ case.owner.name }}</span>
      <ng-container *ngIf="caseOwnerStatus">
        <abbr
          class="text-muted"
          [tooltip]="caseOwnerStatus.tooltip"
          [tooltipHtml]="true"
          (click)="caseOwnerStatus.click && caseOwnerStatus.click()"
          tooltipPlacement="right"
          >{{ caseOwnerStatus.label }}
        </abbr>
      </ng-container>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-3 text-muted">Email</div>
    <div class="col-sm-9 text-truncate is-pii" [title]="case.owner.email">
      {{ case.owner.email }}
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-sm-3 text-muted">Phone</div>
    <div class="col-sm-9 text-truncate is-pii" [title]="case.owner.phone | phone">
      {{ case.owner.phone | phone }}
    </div>
  </div>

  @if (case.owner.case_invitation.status === InvitationStatuses.DRAFT && !case.requires_concierge) {
    <div class="alert alert-info">
      <h5>Invite not sent</h5>
      <p>The contact has not been invited to use Cadence yet.</p>
      <button class="btn btn-primary" (click)="onSendInvitation()">Send invitation&hellip;</button>
    </div>
  }

  <div class="mb-4">
    <span class="me-2">Demo mode</span>
    <span
      [tooltip]="
        'Demo cases are ideal for internal use such as training or demos. They are free of charge and excluded from any usage reports.'
      ">
      <i class="fa-regular fa-circle-info"></i>
    </span>
    <div class="form-check form-switch ps-0">
      <div class="row">
        <div class="ms-0 col-9">
          <label class="form-check-label" for="isDemo">Turn ON to mark this case as a demo case</label>
        </div>
        <div class="col-3 d-flex justify-content-end">
          <input
            class="form-check-input"
            type="checkbox"
            role="switch"
            id="isDemo"
            [(ngModel)]="case.is_test"
            (change)="toggleDemoStatus()" />
        </div>
      </div>
    </div>
  </div>

  <org-case-creator-access-switch *ngIf="case.hasOwnProperty('__abilities')" [case]="case" />
</ng-container>

<form *ngIf="mode === 'edit'" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="row mb-3">
    <label for="invitation-first-name" class="col-sm-3 col-form-label">First</label>
    <div class="col-sm-9">
      <input id="invitation-first-name" type="text" formControlName="first_name" class="form-control" />
    </div>
  </div>
  <div class="row mb-3">
    <label for="invitation-last-name" class="col-sm-3 col-form-label">Last</label>
    <div class="col-sm-9">
      <input id="invitation-last-name" type="text" formControlName="last_name" class="form-control" />
    </div>
  </div>
  <div class="row mb-3">
    <label for="email" class="col-sm-3 col-form-label">Email</label>
    <div class="col-sm-9">
      <input id="email" type="email" formControlName="email" class="form-control" />
      <lib-invalid-feedback for="email"></lib-invalid-feedback>
    </div>
  </div>
  <div class="row mb-3">
    <label for="invitation-phone" class="col-sm-3 col-form-label">Phone</label>
    <div class="col-sm-9">
      <input id="invitation-phone" type="text" formControlName="phone" class="form-control" mask="(000) 000-0000" />
      <lib-invalid-feedback for="phone"></lib-invalid-feedback>
    </div>
  </div>
  <button type="submit" class="btn btn-primary me-3" [ngClass]="{disabled: !form.valid}" [disabled]="!form.valid">
    Save
  </button>
  <button type="button" class="btn btn-link" (click)="onCancelEdit()">Cancel</button>
</form>
