<div class="row">
  <div *ngIf="isLoaded()">
    <ng-container *ngIf="cases.length > 0 || hasFilters; else empty">
      <div class="d-flex justify-content-between align-items-baseline mb-3">
        <h2>Cases</h2>
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              [(ngModel)]="shouldHideDemoCases"
              [ngModelOptions]="{standalone: true}"
              class="form-check-input" />
            Hide demo cases
          </label>
        </div>
      </div>

      <form [formGroup]="filters.form" (ngSubmit)="onFiltersSubmit()" class="card p-4 mb-3">
        <div *ngIf="hasFilters || hasSort">
          <span class="me-2">Filters applied</span>
          <button type="button" class="btn btn-link text-muted p-0 align-baseline" (click)="onClearFilterAndSort()">
            Reset
          </button>
        </div>

        <div class="table-responsive">
          <table class="table table-hover">
            <thead>
            <tr class="align-top">
              <th>
                <div>Contact</div>
                <input
                  type="text"
                  formControlName="owner_search"
                  class="form-control form-control-sm"
                  [class.active-filter]="filters.hasFilters('owner_search')"
                  style="min-width: 120px"
                  placeholder="Filter by name, email, phone" />
              </th>
              <th>
                <div>Deceased</div>
                <input
                  type="text"
                  formControlName="name"
                  [class.active-filter]="filters.hasFilters('name')"
                  class="form-control form-control-sm"
                  style="min-width: 120px"
                  placeholder="Filter by name" />
              </th>
              <th>
                <div>
                  <span [tooltip]="'Name of employee that invited the contact'"> Employee </span>
                </div>
                <select
                  class="form-select form-select-sm"
                  [class.active-filter]="filters.hasFilters('handled_by_id')"
                  style="min-width: 130px"
                  formControlName="handled_by_id"
                  (change)="fetchCases()">
                  <option [ngValue]="null">Filter employee</option>
                  <option *ngFor="let u of users" [ngValue]="u.id">
                    {{ u.name }}
                  </option>
                </select>
              </th>
              <th *ngIf="locations.length">
                <div>Location</div>
                <select
                  class="form-select form-select-sm"
                  [class.active-filter]="filters.hasFilters('location_id')"
                  style="min-width: 130px"
                  formControlName="location_id"
                  (change)="fetchCases()">
                  <option [ngValue]="null">Filter location</option>
                  <option *ngFor="let l of locations" [ngValue]="l.id">
                    <lib-location-name [location]="l"/>
                  </option>
                </select>
              </th>
              <th>
                <span [tooltip]="'Date on which the contact was invited'"> Referred </span>
              </th>
              <th>
                <span [tooltip]="'Date of  sign-up for the case invitation'"> Active since </span>
              </th>
              <th>
                <span [tooltip]="'Invitations sent via email or without an email by using a phone number'">
                  Invite type
                </span>
                <select
                  class="form-select form-select-sm"
                  [class.active-filter]="filters.hasFilters('invite_type')"
                  style="min-width: 130px"
                  formControlName="invite_type"
                  (change)="fetchCases()">
                  <option [ngValue]="null">Filter type</option>
                  <option [ngValue]="'phone'">Phone</option>
                  <option [ngValue]="'email'">Email</option>
                </select>
              </th>
              <th class="text-nowrap">
                <span
                  [tooltip]="'Cadence product type<br>EA: Executor Assistant<br>LP: Legacy Planner'"
                  [tooltipHtml]="true">
                  Type
                </span>
              </th>
              <th *ngIf="activeOrg.activeOrg.meta.pre_need_questionnaire_uuid" class="text-nowrap">
                <span [tooltip]="'Approximate timing to reach out regarding pre-need'"> Pre-need request </span>
                <select
                  (ngModelChange)="onSort($event)"
                  class="form-select form-select-sm"
                  [class.active-filter]="sort.field === 'pre_need_lead_contact_at'"
                  [ngModel]="sort.field ? sort.field + ':' + sort.dir : null"
                  [ngModelOptions]="{standalone: true}">
                  >
                  <option [ngValue]="null">(Sort)</option>
                  <option ngValue="pre_need_lead_contact_at:asc">Chronological</option>
                  <option ngValue="pre_need_lead_contact_at:desc">Reverse chronological</option>
                </select>
              </th>
              <th class="pe-0">
                <div style="width: 80px">
                  <div *ngIf="hasFilters || hasSort">
                    <button type="submit" class="btn btn-sm btn-outline-primary align-bottom text-nowrap w-100 px-2">
                      <i class="fas fa-search"></i> Search
                    </button>
                  </div>
                </div>
              </th>
            </tr>
            </thead>
            <tbody class="small">
            <tr *ngFor="let c of cases">
              <td style="max-width: 15vw" class="text-truncate is-pii">
                <div class="d-flex align-items-center">
                  @if(caseInvitationService.isStatusBounced(c)) {
                    <a
                      class="btn btn-link px-1 text-danger"
                      [routerLink]="['/cases', c.id, 'edit']"
                      tooltip="Invitation email could not be delivered. Click to see more information.">
                      <i class="fa-solid fa-triangle-exclamation"></i>
                    </a>
                  }
                  @if(caseInvitationService.isStatusDraft(c) && !c.requires_concierge) {
                    <a
                      class="btn btn-link px-1 text-primary"
                      [routerLink]="['/cases', c.id, 'edit']"
                      tooltip="Invitation not yet sent. Click to see more information.">
                      <i class="fa fa-triangle-exclamation"></i>
                    </a>
                  }
                  <a class="d-block text-truncate" [routerLink]="['/cases', c.id, 'edit']">
                    {{ c.owner.name }}
                  </a>
                  <lib-is-test-indicator *ngIf="c.is_test" variant="compact" class="ms-2" />
                </div>
              </td>
              <td style="max-width: 15vw" class="text-truncate is-pii">
                <abbr *ngIf="c.status === CaseStatus.STATUS_AFTER_CARE" [tooltip]="c.name" tooltipDelay="1000">
                  {{c.name }}
                </abbr>
              </td>
              <td class="text-nowrap is-pii">
                <ng-container *ngIf="c.caseHandler">
                  <a *ngIf="auth.can('update', c.caseHandler)" [routerLink]="['/users', c.caseHandler.id, 'edit']">
                    {{ c.caseHandler.name }}
                  </a>
                  <span *ngIf="!auth.can('update', c.caseHandler)">
                    {{ c.caseHandler.name }}
                  </span>
                </ng-container>
              </td>
              <td *ngIf="locations.length" style="max-width: 15vw">
                <ng-container *ngIf="c.location">
                  <a
                    class="d-block text-truncate"
                    *ngIf="auth.can('update', c.location)"
                    [routerLink]="['/settings/locations/', c.location.id]">
                    <lib-location-name [location]="c.location"/>
                  </a>
                  <span *ngIf="!auth.can('update', c.location)" class="d-block text-truncate">
                    <lib-location-name [location]="c.location"/>
                  </span>
                </ng-container>
              </td>
              <td class="text-nowrap">
                @if(c.referred_at) {
                  <abbr [tooltip]="c.referred_at | date : 'medium'">
                    {{ c.referred_at | date }}
                  </abbr>
                } @else {
                  <abbr
                    class="text-muted"
                    tooltip="The contact has not been invited to use Cadence yet."
                  >
                    Invite not sent
                  </abbr>
                }
              </td>

              <td class="text-nowrap">
                <abbr [tooltip]="c.serviced_at | date : 'medium'">
                  {{ c.serviced_at | date }}
                </abbr>
              </td>

              <td class="px-4">
                <i *ngIf="c.requires_concierge" class="far fa-phone text-accent"></i>
                <i *ngIf="!c.requires_concierge" class="far fa-envelope text-primary"></i>
              </td>

              <td class="text-nowrap">
                {{ caseStatus(c) }}
              </td>
              <td
                *ngIf="activeOrg.activeOrg.meta.pre_need_questionnaire_uuid"
                class="text-nowrap"
                [class.text-muted]="isPreNeedContactAtExpired(c.pre_need_lead_created_at, c.pre_need_lead_contact_at)"
                [class.text-decoration-line-through]="
                  isPreNeedContactAtExpired(c.pre_need_lead_created_at, c.pre_need_lead_contact_at)
                ">
                <abbr [tooltip]="c.pre_need_lead_contact_at | date">
                  {{c.pre_need_lead_contact_at | date : "MMM d" }}
                </abbr>
                <ng-container *ngIf="c.pre_need_lead_contact_until">
                  -
                  <abbr [tooltip]="c.pre_need_lead_contact_until | date">
                    {{c.pre_need_lead_contact_until | date : "MMM d" }}
                  </abbr>
                </ng-container>
              </td>
              <td>
                <a class="btn btn-link px-1" [routerLink]="['/cases', c.id, 'edit']">
                  <i class="fas fa-edit"></i>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </form>

      <lib-paginator
        [pagination]="pagination"
        (goToPage)="onSelectPage($event)"
        (nextPage)="onNextPage()"
        (prevPage)="onPrevPage()"></lib-paginator>
      <div class="small d-flex align-items-center text-muted" *ngIf="hasFilters">
        <div>Filters applied</div>
        <button class="btn btn-link btn-sm p-0 text-muted ms-2" (click)="onClearFilterAndSort()">Reset</button>
      </div>
    </ng-container>

    <ng-template #empty>
      <div *ngIf="!isFetchingCases" class="col-6 offset-3 mx-auto mt-4 text-center">
        <h2>Are you ready to invite your first contact?</h2>

        <div class="my-5">
          <a class="btn btn-primary" routerLink="/invite">
            <i class="fa fa-plus pe-1"></i>
            Invite contact
          </a>
        </div>

        <div>
          <img src="assets/cases.png" height="288" width="362" />
        </div>
      </div>
    </ng-template>
  </div>
</div>
