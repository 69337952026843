import {Component, Input, OnInit} from '@angular/core'
import {chain, find, remove, upperFirst} from 'lodash'
import {ROLE_ID} from '@/lib/app/models/role.interface'
import {OrgService} from '@/org/app/services/org.service'
import {AuthService} from '@/lib/app/services/auth.service'
import {IOrg} from '@/lib/app/models/org.interface'
import {CaseStatus, ICase} from '@/lib/app/models/case.interface'
import {ConfirmService} from '@/lib/app/services/confirm.service'
import {OrgCaseMemberService} from '@/lib/app/services/org-case-member.service'
import {AlertService} from '@/lib/app/services/alert.service'
import {ICaseMember} from '@/lib/app/models/case-member.interface'
import {CaseStore} from '@/org/app/stores/case.store'
import {environment} from '@/org/environments/environment'
import {ActiveOrgStore} from '@/org/app/stores/active-org.store'
import {FeatureStore} from '@/lib/app/stores/feature.store'
import {
  CaseCollaborationRequestStatuses,
  ICaseCollaborationRequest,
} from '@/lib/app/models/case-collaboration-request.interface'
import {InvitationStatuses} from '@/lib/app/models/case-invitation.interface'

@Component({
  selector: 'org-case-creator-access-switch',
  templateUrl: './case-creator-access-switch.component.html',
})
export class CaseCreatorAccessSwitchComponent implements OnInit {
  @Input() case: ICase
  showInput: boolean = true

  private org: IOrg
  public CaseCollaborationRequestStatuses = CaseCollaborationRequestStatuses

  constructor(
    private _orgs: OrgService,
    private _auth: AuthService,
    private _activeOrg: ActiveOrgStore,
    private _confirm: ConfirmService,
    private _orgCaseMembers: OrgCaseMemberService,
    private _alerts: AlertService,
    private _caseStore: CaseStore,
    private _features: FeatureStore
  ) {}

  ngOnInit(): void {
    this.fetchOrg()
  }

  get doesOrgAllowOrgUserAccessToCases(): boolean {
    return this.org && this._features.isCaseCollaborationEnabled
  }

  get isEnterpriseAdmin(): boolean {
    return this._auth.user.role.id === ROLE_ID.ENTERPRISE_ADMIN
  }

  get caseMemberForCreator(): ICaseMember | null {
    return find(this.case.members, ({user_id}) => user_id === this.case.creator_id)
  }

  get canEnableCollaboAccessWithoutRequest(): boolean {
    return !this.case.owner.case_invitation || this.case?.owner?.case_invitation.status !== InvitationStatuses.ACCEPTED
  }

  get caseCreatorCollabRequest(): ICaseCollaborationRequest | null {
    const member = chain(this.case?.members)
      .filter({case_collaboration_request: {user_id: this.case.creator_id}})
      .maxBy('case_collaboration_request.updated_at')
      .value()

    return member?.case_collaboration_request
  }

  get canSendRequest(): boolean {
    return !this.isCaseCreatorMemberOfCase && !this.caseCreatorCollabRequest
  }

  get canResendRequest(): boolean {
    return (
      this.caseCreatorCollabRequest?.status === CaseCollaborationRequestStatuses.EXPIRED ||
      this.caseCreatorCollabRequest?.status === CaseCollaborationRequestStatuses.REJECTED
    )
  }

  get canRevokeCaseAccess(): boolean {
    return this.isCaseCreatorMemberOfCase && this.canAuthUserRevokeCaseAccess
  }

  get isCaseCreatorMemberOfCase(): boolean {
    return !!this.caseMemberForCreator
  }

  get isCaseCreatorOrgUser(): boolean {
    return (
      this.case.creator.org_id === this.org.id &&
      (this.case.creator.role_id === ROLE_ID.BUSINESS_USER || this.case.creator.role_id === ROLE_ID.BUSINESS_ADMIN)
    )
  }

  get isAuthUserTheCreatorOfCase(): boolean {
    return this._auth.user.id === this.case.creator_id
  }

  get canAuthUserGrantCaseAccess(): boolean {
    return this._auth.can('grantCreatorAccess', this.case)
  }

  get canAuthUserRevokeCaseAccess(): boolean {
    return this._auth.can('revokeCreatorAccess', this.case)
  }

  get canAuthUserToggleCaseAccess(): boolean {
    return (
      (this.isCaseCreatorMemberOfCase && this.canAuthUserRevokeCaseAccess) ||
      (!this.isCaseCreatorMemberOfCase && this.canAuthUserGrantCaseAccess)
    )
  }

  get enduserCaseUrl(): string {
    // todo: can we magic link cases?
    const host = environment.endUserHostNames[0]
    return `//${host}/cases/${this.case.id}`
  }

  get isAfterCareCase(): boolean {
    return this.case.status === CaseStatus.STATUS_AFTER_CARE || this.case.status === CaseStatus.STATUS_AFTER_CARE_LOCKED
  }

  discoverLabelForRequestStatus(status?: CaseCollaborationRequestStatuses) {
    switch (status) {
      case CaseCollaborationRequestStatuses.REJECTED:
        return 'Denied'
      default:
        return upperFirst(status || CaseCollaborationRequestStatuses.ACCEPTED)
    }
  }

  async fetchOrg(): Promise<void> {
    this.org = await this._orgs.fetchById(this._activeOrg.activeOrgId)
  }

  async handleAccessChange() {
    const MSG_GRANT_SELF_ACCESS_NOTIFY_OWNER = `<p>Click "OK" to get access to this Case.</p>
                                                <p>The contact <strong>(${this.case.owner.email})</strong> will be notified
                                                   of collaborator access the first time they log in.</p>
                                                <p>Continue?</p>`

    const MSG_REVOKE_SELF_ACCESS_NOTIFY_OWNER = `<p>Click "OK" to remove your access to this Case.</p>
                                                 <p>Continue?</p>`

    const MSG_REVOKE_OTHERS_ACCESS_NOTIFY_OWNER = `<p>Click "OK" to remove ${this.case.creator.name}'s access to this Case.
                                                      Only ${this.case.creator.name} is able to unlock their access again.</p>
                                                   <p>Continue?</p>`

    if (!this.isCaseCreatorMemberOfCase) {
      const confirmed = await this._confirm.confirm(MSG_GRANT_SELF_ACCESS_NOTIFY_OWNER)

      if (!confirmed) {
        this.showInput = false
        setTimeout(() => (this.showInput = true))
        return
      }
      await this._grantCreatorCaseAccess()
    } else {
      const confirmed = await this._confirm.confirm(
        this.isAuthUserTheCreatorOfCase ? MSG_REVOKE_SELF_ACCESS_NOTIFY_OWNER : MSG_REVOKE_OTHERS_ACCESS_NOTIFY_OWNER
      )

      if (!confirmed) {
        this.showInput = false
        setTimeout(() => (this.showInput = true))
        return
      }
      await this._revokeCreatorCaseAccess()
    }
    await this._caseStore.fetchById(this.case.id)
  }

  async requestCreatorCaseAccess() {
    try {
      const member = await this._orgCaseMembers.post(this.case.id, this.case.creator_id)
      this._alerts.success('Request sent.')
      this._addMemberToCase(member)
    } catch (e) {
      this._alerts.error(e)
    }
  }

  private async _grantCreatorCaseAccess() {
    try {
      const member = await this._orgCaseMembers.post(this.case.id, this.case.creator_id)
      this._alerts.success('Access to case has been granted.')
      this._addMemberToCase(member)
    } catch (e) {
      this._alerts.error(e)
    }
  }

  private async _revokeCreatorCaseAccess() {
    try {
      await this._orgCaseMembers.delete(this.case.id, this.caseMemberForCreator?.id)
      this._alerts.success('Access to case has been removed.')
      this._removeMemberFromCase(this.caseMemberForCreator)
    } catch (e) {
      this._alerts.error(e)
    }
  }

  private _removeMemberFromCase({id}: ICaseMember) {
    remove(this.case.members, {id})
  }

  private _addMemberToCase(member: ICaseMember) {
    this.case.members.push(member)
  }
}
