import {Component, OnInit} from '@angular/core'
import {CaseStatus, ICase} from 'projects/library/src/app/models/case.interface'
import {ActivatedRoute} from '@angular/router'
import {HttpClient} from '@angular/common/http'
import {AlertService} from 'projects/library/src/app/services/alert.service'
import {environment} from '@/org/environments/environment'
import {AuthService} from '@/lib/app/services/auth.service'
import {UntypedFormBuilder, Validators} from '@angular/forms'
import {CaseInvitationService} from '@/org/app/services/case-invitation.service'
import {CaseStore} from '@/org/app/stores/case.store'
import {ActiveOrgStore} from '@/org/app/stores/active-org.store'
import {LocationStore} from '@/lib/app/stores/location.store'
import {ILocation} from '@/org/app/models/location.interface'
import {FileDownloaderService} from '@/lib/app/services/file-downloader.service'
import {ConfirmService} from '@/lib/app/services/confirm.service'
import {isNil} from 'lodash'
import {ITempUploadedFile} from '@/lib/app/models/temp-uploaded-file.interface'

@Component({
  selector: 'org-case-details',
  templateUrl: './case-details.component.html',
})
export class CaseDetailsComponent implements OnInit {
  caseId: ICase['id']
  form = this._fb.group({
    first_name: ['', Validators.required],
    last_name: ['', Validators.required],
    date_of_death: ['', Validators.required],
    date_of_funeral: [''],
  })

  constructor(
    private _route: ActivatedRoute,
    private _caseStore: CaseStore,
    private _http: HttpClient,
    private _alerts: AlertService,
    public locationStore: LocationStore,
    private _fb: UntypedFormBuilder,
    public _caseInvitationService: CaseInvitationService,
    public auth: AuthService,
    private _activeOrg: ActiveOrgStore,
    private _downloader: FileDownloaderService,
    private _confirm: ConfirmService
  ) {}

  get case(): ICase {
    return this._caseStore.byId?.[this.caseId]
  }

  get isAfterCare() {
    return this.case.status === CaseStatus.STATUS_AFTER_CARE || this.case.status === CaseStatus.STATUS_AFTER_CARE_LOCKED
  }

  get isPrePlanner() {
    return (
      this.case.status === CaseStatus.STATUS_PRE_PLANNER || this.case.status === CaseStatus.STATUS_PRE_PLANNER_LOCKED
    )
  }

  ngOnInit() {
    this.caseId = +this._route.parent.snapshot.paramMap.get('id')
    this.fetchCase()
    this.fetchLocations()
  }

  async fetchCase() {
    await this._caseStore.fetchById(this.caseId)
    this.resetDeceasedInfoForm()
  }

  isLoaded() {
    return !isNil(this.case) && !isNil(this.locations)
  }

  get org() {
    return this._activeOrg.org
  }

  get users() {
    return this._activeOrg.users
  }

  get locations(): ILocation[] {
    return this.locationStore.items
  }

  async fetchLocations() {
    await this.locationStore.fetchAll(this._activeOrg.activeOrgId)
  }

  async onDeceasedInfoSubmit() {
    try {
      const response = (await this._http
        .put(`${environment.api}/v3/business/cases/${this.caseId}`, this.form.value)
        .toPromise()) as unknown as {data: ICase}
      this._caseStore.upsert(response.data)
    } catch (e) {
      this._alerts.error(e)
      return
    }
    this._alerts.success('Success')
    this.resetDeceasedInfoForm()
  }

  resetDeceasedInfoForm() {
    this.form.reset({
      first_name: this.case.first_name,
      last_name: this.case.last_name,
      date_of_death: this.case.date_of_death,
      date_of_funeral: this.case.date_of_funeral,
    })
  }

  onReplaceProofOfDeath() {
    this.case.proof_of_death = null
  }

  async onProofOfDeathChange(file: ITempUploadedFile) {
    try {
      const response = (await this._http
        .put(`${environment.api}/v3/business/cases/${this.caseId}`, {proof_of_death_temp_file_id: file.id})
        .toPromise()) as unknown as {data: ICase}
      this._caseStore.upsert(response.data)
      this._alerts.success('Success')
    } catch (e) {
      this._alerts.error(e)
    }
  }

  async onDownloadPod(link: string) {
    const password = await this._confirm.passwordConfirm()
    if (!password) {
      return
    }
    try {
      await this._downloader.download(link, {password})
    } catch (e) {
      this._alerts.error(e)
    }
  }
}
